import { type ReactNode } from 'react'
import { twMerge } from 'tailwind-merge'

type SizeContainerProps = {
  readonly children: ReactNode
  readonly className?: string
  readonly size?: 'sm' | 'md' | 'lg'
}

export const SizeContainer = ({ children, size, className }: SizeContainerProps) => {
  return (
    <div
      className={twMerge(
        'mx-auto w-full',
        size === 'sm' && 'max-w-[40rem]',
        size === 'md' && 'max-w-[75rem]',
        size === 'lg' && 'max-w-[100rem]',
        className,
      )}
    >
      {children}
    </div>
  )
}
