'use client'

import { SizeContainer } from '../../components/layout/SizeContainer/SizeContainer'
import { useTranslation } from '../../i18n/useTranslation'

export const NotFoundPage = () => {
  const { t } = useTranslation()

  return (
    <SizeContainer size='md'>
      <div className='mt-[10vw] flex w-full flex-col items-center justify-center gap-4'>
        <h1 className='text-center text-4xl font-bold'>404</h1>
        <p className='text-center'>{t.general.errors.notFound}</p>
      </div>
    </SizeContainer>
  )
}
